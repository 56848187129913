<template>
	<div>
		<el-dialog title="实习生情况汇总表" :visible.sync="dialogshow" :close-on-click-modal="false"
			:modal-append-to-body="true" :destroy-on-close="true" @close="closeDialog" width="800px"
			custom-class="cus_dialog">
			<el-form :model="EditItem" ref="EditItem" label-width="120px">
				<el-row style="padding-top: 10px;">
					<el-col :span="12">
						<el-form-item label="所在班级">{{ params.class_name }}
						</el-form-item>
					</el-col>
				</el-row>
				<el-row style="padding-top: 10px;">
					<el-col :span="10">
						<el-form-item label="班级总人数">
							<div class="flexStart"><el-input v-model="EditItem.stu_num" type="number"
									@input="handleInput_stu_num"></el-input>
								&nbsp;人
							</div>
						</el-form-item>
					</el-col>
					<el-col :span="10">
						<el-form-item label="在岗学生人数">
							<div class="flexStart"><el-input v-model="EditItem.zg_num" type="number"
									@input="handleInput_zg_num"></el-input>&nbsp;人
							</div>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row style="padding-top: 10px;">
					<el-col :span="10">
						<el-form-item label="升学人数">
							<div class="flexStart"><el-input v-model="EditItem.sx_num" type="number"></el-input>&nbsp;人
							</div>
						</el-form-item>
					</el-col>
					<el-col :span="10">
						<el-form-item label="参军人数">
							<div class="flexStart"><el-input v-model="EditItem.cj_num" type="number"></el-input>&nbsp;人
							</div>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row style="padding-top: 10px;">
					<el-col :span="10">
						<el-form-item label="待岗学生人数">
							<div class="flexStart"><el-input v-model="EditItem.dg_num" type="number"></el-input>&nbsp;人
							</div>
						</el-form-item>
					</el-col>
					<el-col :span="10">
						<el-form-item label="上岗率">{{ EditItem.sg_rate }}&nbsp;%
						</el-form-item>
					</el-col>
				</el-row>
				<el-row style="padding-top: 10px;">
					<el-col :span="10">
						<el-form-item label="学校推荐人数">
							<div class="flexStart"><el-input v-model="EditItem.tj_num" type="number"
									@input="handleInput_tj_num"></el-input>&nbsp;人
							</div>
						</el-form-item>
					</el-col>
					<el-col :span="10">
						<el-form-item label="学校推荐率">
							{{ EditItem.tj_rate }}&nbsp;%
						</el-form-item>
					</el-col>
				</el-row>
				<el-row style="padding-top: 10px;">
					<el-col :span="10">
						<el-form-item label="转岗人数">
							<div class="flexStart"><el-input v-model.number="EditItem.zhg_num" type="number"
									@input="handleInput_zhg_num"></el-input>&nbsp;人
							</div>
						</el-form-item>
					</el-col>
					<el-col :span="10">
						<el-form-item label="稳岗率">{{ EditItem.wg_rate }}&nbsp;%
						</el-form-item>
					</el-col>
				</el-row>
				<el-row style="padding-top: 10px;">
					<el-col :span="10">
						<el-form-item label="专业对口人数">
							<div class="flexStart"><el-input v-model="EditItem.dk_num" type="number"
									@input="handleInput_dk_num"></el-input>&nbsp;人
							</div>
						</el-form-item>
					</el-col>
					<el-col :span="10">
						<el-form-item label="专业对口率">
							{{ EditItem.dk_rate }}&nbsp;%
						</el-form-item>
					</el-col>
				</el-row>


			</el-form>
			<div slot="footer" class="flexEnd" style="width: 100%;">
				<el-button type="" @click="closeDialog()" size="">关闭</el-button>
				<el-button type="primary" @click="saveForm()" size="">保存</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>

export default {
	components: {},
	props: ["params"],
	data() {
		return {
			dialogshow: true,
			userinfo: null,
			EditItem: {
				stu_num: '',
			},
			process_code: "leave_1",
			needChoose: false,//是否需要选择下级
			choosedUser: [],//已选择的用户.
			spword: ""

		}

	},
	mounted() {
		// this.getTeacheInfo()
		this.getData()
	},
	methods: {
		getTeacheInfo() {
			this.$http.post("/api/get_teacher_info").then(res => {
				this.userinfo = res.data
				this.$http.post("/api/get_sign_pic").then(res2 => {
					this.userinfo.sign_img = res2.data.handsign
				})
			})
		},
		getData() {
			console.log(111111, this.params)
			this.$http.post("/api/zhzz_sxqkhz_get", this.params).then(res => {
				let sg_rate = '', tj_rate = '', wg_rate = '',dk_rate = ''
				if (res.data) {
					let stu_num = res.data.stu_num||0
					let zg_num = res.data.zg_num || 0
					let tj_num = res.data.tj_num || 0
					let zhg_num = res.data.zhg_num || 0
					let dk_num = res.data.dk_num || 0
					sg_rate = (Number(zg_num) / Number(stu_num) * 100).toFixed(2)
					tj_rate = (Number(tj_num) / Number(stu_num) * 100).toFixed(2)
					wg_rate = ((Number(zg_num) - Number(zhg_num)) / Number(zg_num) * 100).toFixed(2)
					dk_rate = (Number(dk_num) / Number(stu_num) * 100).toFixed(2)


					res.data.sg_rate = sg_rate
					res.data.tj_rate = tj_rate
					res.data.wg_rate = wg_rate
					res.data.dk_rate = dk_rate
					this.EditItem = res.data

				}
			})

		},
		closeDialog() {
			this.$$parent(this, "dialogshow1", false);
		},
		uploadAttch(e) {

			this.EditItem.files.push(e)
		},
		setInput() {
			this.$forceUpdate()
		},
		handleInput_stu_num(e) {
			console.log(174, e)
			let rate = '', tj_rate = '', dk_rate = ''
			let diff = this.EditItem.zg_num
			if (!diff || !e) {
				rate = ''
			}
			if (diff && e) {
				rate = (Number(diff) / Number(e) * 100).toFixed(2)
			}
			this.EditItem.sg_rate = rate


			let tj_num = this.EditItem.tj_num
			if (!tj_num || !e) {
				tj_num = ''
			}
			if (tj_num && e) {
				tj_rate = (Number(tj_num) / Number(e) * 100).toFixed(2)
			}

			this.EditItem.tj_rate = tj_rate


			let dk_num = this.EditItem.dk_num
			if (!dk_num || !e) {
				dk_num = ''
			}
			if (dk_num && e) {
				dk_rate = (Number(dk_num) / Number(e) * 100).toFixed(2)
			}

			this.EditItem.dk_rate = dk_rate
		},
		handleInput_zg_num(e) {
			// 			在岗 / 总
			// 在岗-转岗/在岗
			let rate = '', rate1 = ''
			let diff = this.EditItem.zhg_num
			if (!diff || !e) {
				rate = ''
			}
			if (diff && e) {
				rate = ((Number(e) - Number(diff)) / Number(e) * 100).toFixed(2)
			}
			let stu_num = this.EditItem.stu_num
			if (!stu_num || !e) {
				rate1 = ''
			}
			if (stu_num && e) {
				rate1 = (Number(e) / Number(stu_num) * 100).toFixed(2)
			}
			this.EditItem.wg_rate = rate
			this.EditItem.sg_rate = rate1

		},
		handleInput_tj_num(e) {
			// 			在岗 / 总
			// 在岗-转岗/在岗
			let rate = '', rate1 = ''
			let stu_num = this.EditItem.stu_num
			if (!stu_num || !e) {
				rate1 = ''
			}
			if (stu_num && e) {
				rate1 = (Number(e) / Number(stu_num) * 100).toFixed(2)
			}
		
			this.EditItem.tj_rate = rate1

		},
		handleInput_zhg_num(e) {
			// 			在岗 / 总
			// 在岗-转岗/在岗
			let rate = ''
			let total = this.EditItem.zg_num
			if (!total || !e) {
				rate = ''
			}
			if (total && e) {
				rate = ((Number(total) - Number(e)) / Number(total) * 100).toFixed(2)
			}
			this.EditItem.wg_rate = rate
		},
		handleInput_dk_num(e) {
			// 			在岗 / 总
			// 在岗-转岗/在岗
			let rate = '', rate1 = ''
			let stu_num = this.EditItem.stu_num
			if (!stu_num || !e) {
				rate1 = ''
			}
			if (stu_num && e) {
				rate1 = (Number(e) / Number(stu_num) * 100).toFixed(2)
			}

			this.EditItem.dk_rate = rate1

		},
		preCheckData(e) {
			let DataInfo = this.EditItem;
			if (!DataInfo.stu_num && DataInfo.stu_num != 0 && DataInfo.stu_num != '0') { this.$message.error('请输入班级总人数'); return false; }
			if (!DataInfo.zg_num && DataInfo.zg_num != 0 && DataInfo.zg_num != '0') { this.$message.error('请输入在岗学生人数'); return false; }
			if (!DataInfo.sx_num && DataInfo.sx_num != 0 && DataInfo.sx_num != '0') { this.$message.error('请输入升学人数'); return false; }
			if (!DataInfo.cj_num && DataInfo.cj_num != 0 && DataInfo.cj_num != '0') { this.$message.error('请输入参军人数'); return false; }
			if (!DataInfo.dg_num && DataInfo.dg_num != 0 && DataInfo.dg_num != '0') { this.$message.error('请输入待岗学生人数'); return false; }
			if (!DataInfo.tj_num && DataInfo.tj_num != 0 && DataInfo.tj_num != '0') { this.$message.error('请输入学校推荐人数'); return false; }
			if (!DataInfo.zhg_num && DataInfo.zhg_num != 0 && DataInfo.zhg_num != '0') { this.$message.error('请输入转岗人数'); return false; }
			if (!DataInfo.dk_num && DataInfo.dk_num != 0 && DataInfo.dk_num != '0') { this.$message.error('请输入专业对口人数'); return false; }
			return true
		},
		saveForm() {

			if (!this.preCheckData()) {
				return
			}
			let EditItem = JSON.parse(JSON.stringify(this.EditItem));
			EditItem.class_id = this.params.class_id
			EditItem.report_year = this.params.report_year
			EditItem.report_month = this.params.report_month
			if (this.EditItem.id) {
				EditItem.id = this.EditItem.id
			}

			

			this.$http.post("/api/zhzz_sxqkhz_edit", EditItem).then(res => {
				this.$message({
					type: 'success',
					message: '保存成功'
				});
				this.$parent.completeSxqkhz();
				this.$parent.dialogshow1 = false;
			})
		},


	},
}
</script>

<style></style>
